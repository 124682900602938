import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Blocks from "../components/Blocks";
import styled from "styled-components";
import {
  layout,
  space,
  display,
  color,
  flexbox,
  position,
} from "styled-system";
import { motion, AnimatePresence } from "framer-motion";

import { GatsbyImage } from "gatsby-plugin-image";
import { Grid, Title, Flex, Text, Box, Label } from "../utils/system";
import Header from "../components/Header";

// import Video from "../components/Video";

const About = ({ data }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [content, setContent] = useState(null);
  const { title, subtitle, people, _rawContent } = data.sanityAbout;
  return (
    <Layout>
      <Header title={title} subtitle={subtitle} />
      {/* <Video url="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" /> */}
      <Flex mb={[3, 5]}>
        <Flex width={["90%", "70%"]}>
          <Blocks blocks={_rawContent} />
        </Flex>
      </Flex>

      <Flex>
        <Flex flexDirection="column" width={["90%"]} mb={6}>
          <Grid
            gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
            gridGap={4}
          >
            {people.map((item) => (
              <motion.div
                layoutId={item.name}
                onClick={() => {
                  setSelectedId(item.name);
                  setContent(item);
                  document.querySelector("body").style.position = "fixed";
                }}
              >
                <Box mb={4}>
                  <GatsbyImage
                    image={item.image.asset.gatsbyImageData}
                    alt={item.name}
                  />
                  <Title mb={1}>{item.name}</Title>
                  <Label color="pink" m={0}>
                    {item.role}
                  </Label>
                </Box>
              </motion.div>
            ))}
          </Grid>
          <AnimatePresence>
            {selectedId && (
              <Bio
                animate={{ opacity: 1 }}
                layoutId={selectedId}
                position="fixed"
                top={0}
                left={0}
                width={["100vw"]}
                height={["100vh"]}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{ transform: "translate3d(0, 0, 0)" }}
              >
                <Grid gridTemplateColumns={["1fr", "1fr", "1fr 1fr"]}>
                  <Box
                    display={["none", "none", "block"]}
                    height="100vh"
                    minHeight="100vh"
                  >
                    <GatsbyImage
                      image={content.image.asset.gatsbyImageData}
                      alt={content.name}
                    />
                  </Box>
                  <Flex
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent={["flex-start", "flex-start", "center"]}
                    bg="white"
                    p={[4, 5]}
                    my={[5, 0, 0]}
                    minHeight="100vh"
                    maxHeight="100vh"
                    style={{ overflowY: "auto" }}
                  >
                    <Title mb={1} mt={0}>
                      {content.name}
                    </Title>
                    <Label color="pink" m={0}>
                      {content.role}
                    </Label>
                    <Blocks blocks={content._rawBio} />
                    <motion.button
                      onClick={() => {
                        setSelectedId(null);
                        document.querySelector("body").style.position =
                          "relative";
                      }}
                    >
                      Close
                    </motion.button>
                  </Flex>
                </Grid>
              </Bio>
            )}
          </AnimatePresence>

          {/* {people &&
              people.map((item, key) => <Team data={item} key={key} />)} */}
        </Flex>
      </Flex>
    </Layout>
  );
};

export default About;

const Bio = styled(motion.div)(
  {
    // position: "fixed",
  },
  space,
  layout,
  display,
  color,
  flexbox,
  position
);

export const pageQuery = graphql`
  query About {
    sanityAbout {
      title
      subtitle
      _rawContent
      people {
        name
        role
        _rawBio
        image {
          asset {
            gatsbyImageData(aspectRatio: 0.8)
          }
        }
      }
    }
  }
`;
